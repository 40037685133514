import React from 'react'
import PropTypes from 'prop-types'
import ImageBlock from 'Components/ImageBlock'
import CheckIcon from 'Svgs/Check'
import FlexGroup from 'Components/FlexGroup'

const UseCaseBlock = ({ reverse, image, title, description }) => {
  const TextPart = () => (
    <FlexGroup
      style={{ alignItems: 'flex-start' }} // override center align
      left={
        <CheckIcon className="text-green-500 bg-green-200 rounded-full p-1 align-middle mt-2 h-8 mr-4" />
      }
      middle={
        <div>
          <p className="text-3xl md:text-4xl mb-2 text-gray-700">{title}</p>
          <p variant="h6" className="text-gray-600 text-xl md:text-2xl">
            {description}
          </p>
        </div>
      }
    />
  )

  const ImagePart = () => (
    <ImageBlock
      lazyloadProps={{ offset: 200 }}
      className="h-auto w-auto max-w-full"
      style={{
        maxHeight: '200px',
      }}
      src={image}
      height="210px"
    />
  )
  return (
    <div className="grid grid-cols-2 my-16 items-center">
      <div className="py-4 md:py-8 md:px-10 col-span-2 md:col-span-1">
        <div className="md:hidden">
          <TextPart />
        </div>
        <div className="hidden md:block">
          {reverse ? <TextPart /> : <ImagePart />}
        </div>
      </div>
      <div className="py-4 md:py-8 md:px-10 col-span-2 md:col-span-1">
        <div className="md:hidden">
          <ImagePart />
        </div>
        <div className="hidden md:block">
          {reverse ? <ImagePart /> : <TextPart />}
        </div>
      </div>
    </div>
  )
}

UseCaseBlock.defaultProps = {
  reverse: false,
}

UseCaseBlock.propTypes = {
  reverse: PropTypes.bool,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default UseCaseBlock
