import React from 'react'
import PropTypes from 'prop-types'

const getPadding = ({ size }) => {
  if (size === 'large') return 'px-6 py-3'
  if (size === 'small') return 'px-2 py-0.5'
  return 'px-4 py-1.5'
}

const getFontSize = ({ size }) => {
  // increase these sizes later when website font sizes have been increased
  if (size === 'large') return 'text-2xl'
  if (size === 'small') return 'text-base'
  return 'text-lg'
}

const getTextColor = ({ color, variant, disabled, textColor }) => {
  let finalTextColor = 'text-white' // primary
  if (color === 'secondary') finalTextColor = 'text-indigo-600'
  if (variant === 'text') {
    // text variant always has dark text
    finalTextColor = 'text-indigo-600'
  }
  if (disabled) {
    finalTextColor = 'text-gray-400'
  }
  if (textColor) {
    // override
    finalTextColor = textColor
  }
  return finalTextColor
}

const getBackgroundColor = ({ color, variant, disabled, bgColor }) => {
  let finalBgColor = 'bg-indigo-500'
  // eslint-disable-next-line prefer-destructuring
  if (color === 'secondary') finalBgColor = 'bg-indigo-100'
  if (variant === 'text') {
    // text variant always has transparent bg
    finalBgColor = 'bg-transparent'
  }
  if (disabled) {
    finalBgColor = 'bg-gray-200'
  }
  if (disabled && color === 'secondary') {
    finalBgColor = 'bg-transparent'
  }
  if (bgColor) {
    // override
    finalBgColor = bgColor
  }
  return finalBgColor
}

const getHoverBackgroundColor = ({
  color,
  variant,
  hoverBgColor,
  disabled,
}) => {
  let bgColor = 'hover:shadow-none hover:bg-indigo-700'
  // eslint-disable-next-line prefer-destructuring
  if (color === 'secondary') bgColor = 'hover:shadow-none hover:bg-indigo-200'
  if (variant === 'text') {
    // text variant always has light bg on hover
    bgColor = 'hover:shadow-none hover:bg-indigo-200'
  }
  if (hoverBgColor) {
    // override
    bgColor = `hover:${hoverBgColor}`
  }
  if (disabled) {
    // no hover change
    bgColor = ''
  }
  return bgColor
}

const getBorder = ({ color, variant, disabled }) => {
  let border = 'border border-solid border-transparent' // to avoid shift
  if (color === 'secondary' && !disabled)
    border = 'border border-solid border-indigo-300'
  if (color === 'secondary' && disabled)
    border = 'border border-solid border-gray-300'
  if (variant === 'text') {
    // text variant always has no border
    border = 'border-none'
  }
  return border
}

const getBoxShadow = ({ variant, disabled }) => {
  let shadow = 'shadow-sm'
  if (variant === 'text') {
    // text variant has no shadow
    shadow = 'shadow-none'
  }
  if (disabled) {
    shadow = 'shadow-none'
  }
  return shadow
}

// using forward ref to make it work with nextjs' link comp

const CustomButton = React.forwardRef(function CustomButton(props, ref) {
  const {
    children,
    size,
    color,
    variant,
    className,
    disabled,
    bgColor,
    hoverBgColor,
    textColor,
    ...extraProps
  } = props
  return (
    <button
      type="button"
      ref={ref}
      size={size}
      color={color}
      variant={variant}
      className={`outline-none rounded-xl tracking-wide leading-7 ${
        disabled ? 'cursor-not-allowed' : ''
      } ${getFontSize(props)} ${getPadding(props)} ${getTextColor(
        props
      )} ${getBackgroundColor(props)} ${getHoverBackgroundColor(
        props
      )} ${getBorder(props)} ${getBoxShadow(props)} ${className}`}
      {...extraProps}
    >
      <span className="flex items-center justify-center">{children}</span>
    </button>
  )
})

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
}

CustomButton.defaultProps = {
  size: 'medium',
  variant: 'contained',
  color: 'primary',
  className: '',
  hoverBgColor: null,
  bgColor: null,
  textColor: null,
  disabled: false,
}

CustomButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.string,
  className: PropTypes.string,
  hoverBgColor: PropTypes.any,
  bgColor: PropTypes.any,
  disabled: PropTypes.bool,
  textColor: PropTypes.any,
}

export default CustomButton
