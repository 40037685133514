import React from 'react'
import Image from 'Components/Image'
import Layout from 'Components/Layout'
import Link from 'next/link'
import PageHead from 'Components/PageHead'
import Button from 'Components/Button'
import CtaBar from 'Components/page-components/landing-page/CTABar'
import CustomerBlock from 'Components/page-components/landing-page/CustomerBlock'
import FeaturePoint from 'Components/page-components/landing-page/FeaturePoint'
import TestimonialBlock from 'Components/page-components/landing-page/TestimonialBlock'
import UseCaseBlock from 'Components/page-components/landing-page/UseCaseBlock'

const sectionClasses = 'min-h-screen sm:pt-5 sm:pr-0 sm:pb-20 sm:pl-0'
const sectionContentClass = 'max-w-screen-xl my-0 mx-auto p-2 md:p-4 pb-16'
const sectionTitleClasses =
  'tracking-wide text-indigo-700 text-3xl text-center md:text-4xl xl:text-5xl mt-8 mb-4 font-semibold'

const BannerImage = () => (
  <Image
    alt="banner"
    height={467}
    width={700}
    className="rounded-xl"
    src="/images/landing/hero.jpg"
  />
)

const LandingPage = () => {
  return (
    <div>
      <PageHead
        title="Ylurn • Grow And Manage Your Teaching Business Online"
        description="Teach your students online, sell online courses, get your own test portal, live classes and much more. All on your own branded app and website."
      />
      <section className={sectionClasses}>
        <div className={sectionContentClass}>
          <div className="grid grid-cols-2 gap-4 lg:gap-8 my-2 my:my-10 lg:my-20">
            <div className="col-span-2 lg:col-span-1">
              <h1 className={`${sectionTitleClasses} text-center mt-8 mb-4`}>
                Grow And Manage Your Teaching Business&nbsp;
                <span className="text-green-600">Online</span>
              </h1>
              <div className="lg:hidden flex justify-center p-4">
                <BannerImage />
              </div>
              <p className="text-center mx-auto text-gray-700 py-4 text-xl md:p-4 md:max-w-2xl md:text-2xl">
                Teach your students online, sell online courses, get your own
                test portal, live classes and much more. All on your own branded
                app and website.
              </p>
              <CtaBar />
            </div>
            <div className="col-span-2 lg:col-span-1 hidden lg:block">
              <div className="flex justify-center p-4">
                <BannerImage />
              </div>
            </div>
          </div>
        </div>
        <style jsx>
          {`.no-scrollbar::-webkit-scrollbar {
            display: none;
          }
          
          .no-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
          }`}
        </style>
        <div
          className="no-scrollbar mx-auto px-4 pb-20 max-w-screen-xl overflow-auto whitespace-nowrap"
          style={{ width: 'calc(100vw - 30px)' }}
        >
          <CustomerBlock
            coaching="Inspire Institution"
            location="Hyderabad"
            logo="/images/landing/inspire.png"
          />
          <CustomerBlock
            coaching="Conceptree Learning"
            location="Chennai"
            logo="/images/landing/conceptree.png"
          />
          <CustomerBlock
            coaching="Akademia Institute"
            location="Delhi"
            logo="/images/landing/akademia.png"
          />
          <CustomerBlock
            coaching="Generation Z Academy"
            location="Bilaspur"
            logo="/images/landing/genz.png"
          />
          <CustomerBlock
            coaching="Madhi Academy"
            location="Chennai"
            logo="/images/landing/madhi.png"
          />
        </div>
      </section>
      <section className={`${sectionClasses} bg-gray-100`}>
        <div className={sectionContentClass}>
          <UseCaseBlock
            title="Get branded mobile apps and website"
            description="Conduct live classes, sell courses and teach your students all on your own branded Android/iOS apps and website."
            image="/images/landing/img-multidevice.png"
          />
          <UseCaseBlock
            title="Sell online courses and programs"
            description="Grow your business by selling online programs such as video-lecture courses, test series, live classes, DLPs etc"
            image="/images/landing/img-sell-courses.png"
            reverse
          />
          <UseCaseBlock
            title="Get your own online test portal"
            description="Conduct online tests on your own mobile apps and website. Automatically upload all your question papers. Get instant test reports and analysis."
            image="/images/landing/img-test-portal.png"
          />
          <UseCaseBlock
            title="Connect and teach your offline students"
            description="Conduct invite-only online classes to teach your offline students. Invite students by sharing unique access-codes via SMS/email/print."
            image="/images/landing/img-teach-offline.png"
            reverse
          />
          <UseCaseBlock
            title="Build your institute's digital content library"
            description="Build your own digital content library containing tests, questions, files and video-lectures. Upload and share all this content across all your courses."
            image="/images/landing/img-digital-library.png"
          />
          <CtaBar />
        </div>
      </section>
      <section className={sectionClasses}>
        <div className={sectionContentClass}>
          <p className={`${sectionTitleClasses} mt-24 mb-24 text-center`}>
            What our customers say...
          </p>
          <div className="grid grid-cols-3 gap-6">
            <TestimonialBlock
              coaching="Conceptree Learning"
              location="Chennai"
              testimonial="We launched our digital initiative 'Conceptree Digital' with the help of Ylurn in less than 3 weeks and our digital program revenues were comparable to our offline revenues in less than 6 months."
              logo="/images/landing/conceptree.png"
            />
            <TestimonialBlock
              coaching="Madhi Academy"
              location="Chennai"
              testimonial="Ylurn has completely surpassed our expectations. It is truly upstanding and is behind its product 100%. Thank you for making it painless, pleasant and most of all hassle free to teach my students online!"
              logo="/images/landing/madhi.png"
            />
            <TestimonialBlock
              coaching="Generation Z Academy"
              location="Bilaspur"
              testimonial="We launched our online live integrated online classes with Ylurn during the Covid lockdown. We have seen more than 600 students from our city register for our live classes. The support team is very good as well."
              logo="/images/landing/genz.png"
            />
          </div>
          <div>
            <h3 className={`${sectionTitleClasses} mt-28`}>Pricing</h3>
            <p className="text-xl text-center text-gray-600">
              We have pricing plans that fit businesses of all sizes
            </p>
            <div className="text-center mb-20">
              <Link href="/pricing">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="rounded-lg my-8 font-semibold tracking-widest text-xl bg-yellow-500 hover:bg-yellow-600 outline-none"
                >
                  Check pricing plans
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className={`${sectionClasses} bg-gray-100`}>
        <div className={sectionContentClass}>
          <h3 className={`${sectionTitleClasses} mt-24 mb-16`}>All Features</h3>
          <div className="grid grid-cols-3 gap-6 mt-6 mb-16 mx-auto">
            <div className="col-span-3 md:col-span-1">
              <FeaturePoint text="Live lectures" />
              <FeaturePoint text="Video lecture upload" />
              <FeaturePoint text="File upload" />
              <FeaturePoint text="Unlimited courses" />
              <FeaturePoint text="SMS notifications" />
              <FeaturePoint text="Email notifications" />
              <FeaturePoint text="App notifications" />
              <FeaturePoint text="Payment gateway" />
            </div>
            <div className="col-span-3 md:col-span-1">
              <FeaturePoint text="Landing page builder" />
              <FeaturePoint text="Digital content library" />
              <FeaturePoint text="Student management" />
              <FeaturePoint text="Appearance customisation" />
              <FeaturePoint text="Discussion forum" />
              <FeaturePoint text="Unlimited students" />
              <FeaturePoint text="Announcements" />
              <FeaturePoint text="Marketing tools" />
            </div>
            <div className="col-span-3 md:col-span-1">
              <FeaturePoint text="Online tests" />
              <FeaturePoint text="Test analysis" />
              <FeaturePoint text="Chat and comments" />
              <FeaturePoint text="Private notes" />
              <FeaturePoint text="Coupon/discount codes" />
              <FeaturePoint text="Invite-only courses" />
              <FeaturePoint text="Free courses" />
              <FeaturePoint text="Student performance" />
            </div>
          </div>
          <CtaBar />
        </div>
      </section>
    </div>
  )
}

export default Layout(LandingPage, {
  sidebarVariant: 'LANDING',
  isFooterPresent: true,
  fullWidth: true,
})
