import React from 'react'
import PropTypes from 'prop-types'
import ImageBlock from 'Components/ImageBlock'

const TestimonialBlock = ({ logo, coaching, testimonial, location }) => (
  <div className="col-span-3 md:col-span-1">
    <div className="shadow-md hover:shadow-xl my-8 mx-4 px-4 pt-4 pb-12 rounded-lg border-2 border-indigo-600 h-96">
      <div className="text-center">
        <ImageBlock
          height="50px"
          width="50px"
          src={logo}
          className="h-12 w-12 p-2 bg-white -mt-10"
        />
      </div>
      <br />
      <p className="text-lg text-center mb-4">
        {coaching}
        <br />
        <span className="text-gray-600 block">{location}</span>
      </p>
      <p className="text-base text-center text-gray-700">
        {`"${testimonial}"`}
      </p>
    </div>
  </div>
)

TestimonialBlock.propTypes = {
  logo: PropTypes.string.isRequired,
  coaching: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
}

export default TestimonialBlock
