import React from 'react'
import PropTypes from 'prop-types'
import ImageBlock from 'Components/ImageBlock'

const CustomerBlock = ({ logo, coaching, location }) => (
  <div
    style={{
      width: '270px',
    }}
    className="select-none inline-block shadow-lg mb-4 mx-4 p-4 rounded-lg border border-gray-200"
  >
    <div className="text-center text-lg">
      <ImageBlock
        height="30px"
        width="30px"
        src={logo}
        className="h-7 w-7 mr-2 bg-white align-middle"
      />
      {coaching}
      <span className="text-base text-gray-600 block">{location}</span>
    </div>
  </div>
)

CustomerBlock.propTypes = {
  logo: PropTypes.string.isRequired,
  coaching: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
}

export default CustomerBlock
