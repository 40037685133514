import React from 'react'
import PropTypes from 'prop-types'

const FlexGroup = ({ left, right, middle, className, style }) => (
  <div className={`flex items-center ${className}`} style={style}>
    <div className="inline-block">{left}</div>
    <div className="flex-auto inline-block">{middle}</div>
    <div className="inline-block">{right}</div>
  </div>
)

FlexGroup.defaultProps = {
  left: <div />,
  middle: <div />,
  right: <div />,
  style: {},
  className: '',
}

FlexGroup.propTypes = {
  left: PropTypes.object,
  middle: PropTypes.object,
  right: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default FlexGroup
