import Router from 'next/router'
import ModifyUrlParam from 'Utils/ModifyUrlParam'

const modalName = 'contactUs'

const OpenRequestDemoDialog = () => {
  const { asPath, pathname, query } = Router.router
  query.modal = modalName
  let url = asPath
  url = ModifyUrlParam(url, 'modal', modalName)
  Router.push({ pathname, query }, url, { shallow: true })
}

export default OpenRequestDemoDialog
