import React from 'react'
import Link from 'next/link'
import Button from 'Components/Button'
import OpenRequestDemoDialog from 'Utils/OpenRequestDemoDialog'

const commonButtonClasses =
  'rounded-lg mx-2 mb-4 mt-2 font-semibold tracking-wider text-lg p-3 xl:px-5 xl:text-xl xl:p-3'

const CTABar = () => (
  <div className="text-center my-6">
    <Link href="/institute/signup">
      <Button
        className={`${commonButtonClasses} bg-yellow-500 hover:bg-yellow-600`}
        size="large"
        color="primary"
        variant="contained"
        style={{ width: '275px' }}
      >
        START FREE TRIAL
      </Button>
    </Link>
    <Button
      className={`${commonButtonClasses} text-indigo-500 border-indigo-300`}
      size="large"
      color="secondary"
      style={{ width: '275px' }}
      onClick={OpenRequestDemoDialog}
    >
      REQUEST A DEMO
    </Button>
  </div>
)

export default CTABar
